import { SpinnerSizeEnum } from '@types/enums/Loading.enum'
import { PaginationTypeEnum } from '@types/enums/Pagination.enum'
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { SizeNamesEnum, TypesNamesEnum } from 'enums/Button.enum'
import Loading from 'components/atoms/Loading'
import Pagination from 'components/organisms/AbraTable/table-components/Pagination'
import { toast, ToastType } from 'components/organisms/Toast'
import bigDecimal from 'js-big-decimal'
import React, { useState } from 'react'
import { CustodyTransaction, useGetCustodyTransactionTypeQuery, useGetCustodyTransactionsCSVMutation } from 'state/store/custodyApi'
import styled from 'styled-components'
import { CustodyMainText, CustodyStyledTable, CustodySubText } from './CustodyStyles'
import useCustody from './useCustody'
import Modal from 'components/organisms/Modal'
import Button from 'components/atoms/Button';
import WhiteSpinner from 'components/atoms/Spinner/WhiteSpinner';
import { Download } from 'assets/icons';
import { formatDate } from 'shared'
import getUsDollar from 'shared/dollarFormat'
import TokenQuantity from 'components/atoms/TokenQuantity/TokenQuantity'
import ExcludingIncome from './ExcludingIncome'
import { isNegative } from 'utils/isNegative';


const CustodyTransactionDetails = () => {
  const options = ['Income', 'Credit', 'Debit', 'Deposit', 'Divest', 'Invest', 'Reversal', 'Transfer', 'Withdrawal'];
  const [selectedOptions, setSelectedOptions] = useState<string[]>(options)
  const [page, setPage] = useState(1)
  const query = useGetCustodyTransactionTypeQuery({ pageRequest:page, sizeRequest: 10, actionNameSelections: selectedOptions })
  const [openModal, setOpenModal] = useState(false);
  const [transactionsCsv, transactionsCsvMeta] = useGetCustodyTransactionsCSVMutation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const isDateRangeValid = startDate <= endDate && startDate <= new Date();
  const getCSV = async () => {
    if (!isDateRangeValid) {
      return;
    }
    await transactionsCsv({from: startDate, to: endDate}).unwrap()
    toast.show({
      type: ToastType.Success,
      title: "File download successfully",
      content: "Check your download folder",
    });
    setOpenModal(false)
  };

  const changePage = (page: number) => {
    setPage(page)
  }

  const stateChanged = (options: string[]) => {
    setSelectedOptions(options)
  }

  return (
  <div>
    <TokenSummaryTitleBox>
      <TokenSummaryTitle>Transaction Details</TokenSummaryTitle>
      {!query.isFetching && (
      <DownloadSpreadSheetSpan onClick={() => setOpenModal(true)}>
        {transactionsCsvMeta.isLoading ? (
          <>
            <WhiteSpinner />
          </>
        ) : (
          <>
            <Download color='white' size={15} />
            <span>Download</span>
          </>
        )}
      </DownloadSpreadSheetSpan>
    )}

      </TokenSummaryTitleBox>
    <Modal modalProps={{width: 600}} visible={openModal} onClose={() => setOpenModal(false)} header='Download Transactions as CSV'>
      <ModalBody>
        <ModalDescription>Select the date range for the transaction data</ModalDescription>
        <CalendarRow>
        <CalendarBox>
          <span>From</span>
          <DatePicker 
            selected={startDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            disabledKeyboardNavigation
            inline showFullMonthYearPicker onChange={(date) => setStartDate(date!)} maxDate={endDate}></DatePicker>
          <span>{formatDate(startDate)}</span>
        </CalendarBox>
        <CalendarBox>
          <span>To</span>
          <DatePicker   
            selected={endDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            disabledKeyboardNavigation
            inline showFullMonthYearPicker onChange={(date) => setEndDate(date!)} maxDate={new Date()}></DatePicker>
          <span>{formatDate(endDate)}</span>
        </CalendarBox>
        </CalendarRow>
        <ButtonRow>
          <Button buttonType={TypesNamesEnum.SECONDAY} size={SizeNamesEnum.EXTRA_LARGE_PLUS} label='Cancel' onClick={() => setOpenModal(false)} />
          <Button disabled={!isDateRangeValid} isLoading={transactionsCsvMeta.isLoading} buttonType={TypesNamesEnum.ACCENT} size={SizeNamesEnum.EXTRA_LARGE_PLUS} label='Download' onClick={getCSV} />
        </ButtonRow>
      </ModalBody>
    </Modal>
    {query.isLoading && <Loading size={SpinnerSizeEnum.LARGE} showText={false}/>}
    {!query.isLoading && query.data?.content.length === 0 && <CustodyMainText>No transactions found</CustodyMainText>}
    {!query.isLoading && !!query.data?.content.length && 
    <>
    <CustodyStyledTable blur={query.isFetching}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Asset</th>
          <th>Action</th>
          <th>Type</th>
          <th>Quantity</th>
          <th>Network Fee</th>
          <th>Blockchain Id</th>
          <th>Transaction Id</th>
          <th>Strategy</th>
        </tr>
      </thead>
      <tbody>
        {!!selectedOptions.length && query.data.content.map((record, index) => <TokenSummaryRow key={index} record={record} />)}
      </tbody>
    </CustodyStyledTable>
    {query.data && selectedOptions.length ? <>
      <Pagination 
          totalRecords={query.data?.totalElements} 
          activePageNumber={query.data?.number} 
          perPage={query.data.size} 
          handlePagination={(page: number)=>{changePage(page)}} 
          variant={PaginationTypeEnum.PAGES}/>
    </> : <></>}
    </>
  }
  </div>
  )
}

interface TokenSummaryRowParams {
  record: CustodyTransaction
}

const TokenSummaryRow: React.FC<TokenSummaryRowParams> = ({record}) => {
  const { getAssetByIdentifier, getPrice } = useCustody()
  const asset = getAssetByIdentifier(record.amount?.asset)
  const feeAsset = getAssetByIdentifier(record?.networkFee?.asset)
  
  const generateFeeValue = (record: CustodyTransaction) => {
    const rate = new bigDecimal(record?.networkFeeConvenienceRate?.rate ?? '0')
    const amount = new bigDecimal(record?.networkFee?.amount ?? '0')
    const value = rate.multiply(amount)
    return value.round(2).getValue()
  }

  const generateRecordValue = (record: CustodyTransaction) => {
    const rate = new bigDecimal(record.amountConvenienceRate?.rate ?? '0')
    const amount = new bigDecimal(record.amount.amount ?? '0')
    const value = rate.multiply(amount)
    return value.round(2).getValue()
  }

  const goTo = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  }

  const copyAddress = (address: string) => {
    try {
      navigator.clipboard.writeText(address)
      toast.show({
        title: 'Value copied',
        content: 'The value was copied to the clipboard',
        duration: 5000,
        type: ToastType.Info
      })
    } catch(e) {

    }
  }

  return (
    <tr>
      <td>
        <CustodyMainText>
          {formatDate(record.dateTime)}
        </CustodyMainText>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText>{asset?.name}</CustodyMainText>
          <CustodySubText>{record.networkName}</CustodySubText>
        </TextGroup>
      </td>
      <td>
        <CustodyMainText>
          {record.actionName}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {record.transactionCategory}
        </CustodyMainText>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText isNegative={isNegative(record.amount?.amount)}>
            <TokenQuantity asset={record.amount?.asset} quantity={record.amount?.amount} />
          </CustodyMainText>
          <CustodySubText isNegative={isNegative(record.amount?.amount)}>
          <>
            ({record.amountConvenienceRate?.rate ? getUsDollar(generateRecordValue(record)) : "-"})
          </>
            
          </CustodySubText>
        </TextGroup>
      </td>
      <td>
        <TextGroup>
          {record.networkFee?.amount ? <>
          <CustodyMainText isNegative={isNegative(record.networkFee?.amount)}>
            <TokenQuantity asset={record.networkFee?.asset} quantity={record.networkFee?.amount} />
          </CustodyMainText>
          <CustodySubText isNegative={isNegative(record.networkFee?.amount)}>
            ({getUsDollar(generateFeeValue(record))})</CustodySubText>
          </> : <CustodyMainText>-</CustodyMainText>}
        </TextGroup>
      </td>
      <td>
        {record.blockchainURL ? 
        <CustodyMainText onClick={() => goTo(record.blockchainURL)}>
        <CryptoAddressBox title={record.blockchainURL}>
          <CryptoLink>{record.blockchainHash}</CryptoLink>
        </CryptoAddressBox>
        </CustodyMainText>
        :
        <CustodyMainText onClick={() => copyAddress(record.blockchainHash)}>
        <CryptoAddressBox title={record.blockchainHash}>
          <CryptoAddress>{record.blockchainHash}</CryptoAddress>
        </CryptoAddressBox>
        </CustodyMainText>}
      </td>
      <td>{record.abraTxUID}</td>
      <td>
        <CustodyMainText>
          {record.strategyName}
        </CustodyMainText>
      </td>
    </tr>
  )
}


const CryptoAddressBox = styled.div`
  cursor: pointer;
  max-width: 6vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CryptoAddress = styled.span`
  text-overflow: ellipsis;
  max-width: 10vw;
  font-size: 80%;
  overflow: hidden;
`
const CryptoLink = styled.span`
  text-overflow: ellipsis;
  max-width: 10vw;
  font-size: 80%;
  overflow: hidden;
  text-decoration: underline;
  color: #A399F6;
  
`
const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const TokenImageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6vw;
`
const TokenSummaryTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-bottom: 6px;
`
const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vh;
  padding: 2vh 2vw;
`
const ModalDescription = styled.p`
  display: flex;
  justify-content: center;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: stretch;
  gap: 1vw;
`;

const DownloadSpreadSheetSpan = styled.span`
  background-color: #6f2acd80;
  border-radius: 6px;
  font-size: 80%;
  display: flex;
  align-items: center;
  gap: 1vw;
  padding: 0.6vw 1vw;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  &:hover {
    background-color: #292749;
  }
`;
const CalendarRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const CalendarBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  .react-datepicker { 
    margin: 0.5vw;
    background-color: #ffffff11;
    color: white;
    .react-datepicker__day {
      color: white;
    }
    .react-datepicker__day--keyboard-selected {
      color: black;
    }
    .react-datepicker__header > react-datepicker__current-month {
      color: white; 
    }
    .react-datepicker__day--disabled {
      background-color: rgb(39 38 46);
      color: gray;
    }
    .react-datepicker__day--in-selecting-range {
      background-color: rgb(153 128 202);
    }
    .react-datepicker__day--in-selecting-range:hover {
      background-color: rgb(153 128 202);
    }
    .react-datepicker__day--in-range {
      background-color: rgb(91 74 123);
      color: white;
    }
    .react-datepicker__month-text:hover {
      background-color: rgb(131,91,208) !important;
      color: white !important;
    }
  }
`;

export default CustodyTransactionDetails
